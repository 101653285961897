"use client"

import * as React from "react"
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from "@/components/ui/navigation-menu"
import  { SignOutButton } from "@clerk/clerk-react"
import { Button } from "@/components/ui/button"


export function NavBar() {
return (
    <div className="flex w-screen">
        <NavigationMenu>
            <NavigationMenuList>
                <NavigationMenuItem>
                    <ul className="grid gap-10 p-4 md:grid-cols-2 justify-between">
                        <NavigationMenuLink href="/">Dashboard</NavigationMenuLink>
                        <NavigationMenuLink>
                            <SignOutButton />
                        </NavigationMenuLink>
                    </ul>
                </NavigationMenuItem>
                {!window.location.pathname.includes('/create') && (
                    <NavigationMenuItem>
                        <Button onClick={() => window.location.href = '/create'}>Create Interview</Button>
                    </NavigationMenuItem>
                )}
            </NavigationMenuList>
        </NavigationMenu>
    </div>
)
}

