import React from "react";
import NavigationBar from "@/components/ui/NavigationBar";
import NewInterview from "@/components/ui/NewInterview";
import GuestCard from "@/components/ui/GuestCard";
import useFetchInterviews from "./fetchInterviews";

const Dashboard = () => {
  const { data, isLoading } = useFetchInterviews();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <NavigationBar title="Dashboard" />
      <div className="grid h-auto w-full pl-[56px] gap-5">
        {data[0].message ? (
          <div>No interviews created yet</div>
        ) : (
          data.map((interview, index) => (
            <GuestCard
              key={interview.id}
              guest_name={interview.name}
              guest_linkedin={interview.linkedin_url}
              podcast_theme={interview.theme}
              interview_id={interview.id}
              enabled={index === 0 ? true : interview.subscription_status}
            />
          ))
        )}
        <div className="max-w-max">
          <NewInterview />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
