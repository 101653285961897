import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;

const useFetchDeleteInterview = () => {
  const { getToken } = useAuth();
  console.log("API_URL", API_URL);
  const queryClient = useQueryClient();

  const deleteInterview = async (guest_id) => {
    const token = await getToken();
    // const response = await fetch(`https://api.zerotocast.com/interview/${guest_id}`, {
    const response = await fetch(
      `${API_URL}/interview/${guest_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to delete interview");
    }
    return response.json();
  };

  return useMutation({
    mutationFn: deleteInterview,
    onSuccess: () => {
      queryClient.invalidateQueries(["interviews"]);
    },
  });
};

export default useFetchDeleteInterview;
