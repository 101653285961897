import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { Loader2 } from "lucide-react";

import useFetchCreateInterview from "pages/Dashboard/fetchCreateInterview"; // Adjust the path as needed

const newInterviewSchema = z
  .object({
    guest_name: z
      .string()
      .min(2, {
        message: "Name must be at least 2 characters.",
      })
      .max(100, {
        message: "Name must be at most 100 characters.",
      }),
    linkedin_url: z.string().url({
      message: "Invalid URL.",
    }),
    theme: z
      .string()
      .min(2, {
        message: "Theme must be at least 2 characters.",
      })
      .max(100, {
        message: "Theme must be at most 100 characters.",
      }),
  })
  .required();

const NewInterview = () => {
  const form = useForm({
    resolver: zodResolver(newInterviewSchema),
    defaultValues: {
      guest_name: "John Doe",
      linkedin_url: "https://linkedin.com/in/john-doe",
      theme: "Design",
    },
  });

  const {
    mutate: createInterview,
    isPending,
    isError,
    error,
  } = useFetchCreateInterview();

  const onSubmit = (values) => {
    createInterview({
      name: values.guest_name,
      linkedin_url: values.linkedin_url,
      theme: values.theme
    });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="default">New Guest Research</Button>
      </DialogTrigger>
      <DialogContent className="max-w-max">
        <DialogHeader>
          <DialogTitle>New Guest Research</DialogTitle>
          {!isPending ? (
            <>
              <DialogDescription>
                Let's research a guest for your podcast
              </DialogDescription>
            </>
          ) : (
            <DialogDescription>
              Research in progress it may take less than a minute
            </DialogDescription>
          )}
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            {!isPending ? (
              <>
                <FormField
                  control={form.control}
                  name="guest_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Guest Name</FormLabel>
                      <FormControl>
                        <Input placeholder="John Doe" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="linkedin_url"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Guest LinkedIn URL</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="https://linkedin.com/in/john-doe"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="theme"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Podcast Theme</FormLabel>
                      <FormControl>
                        <Input placeholder="Design" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            ) : null}

            <DialogFooter>
              <Button type="submit" disabled={isPending}>
                {isPending ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Loading...
                  </>
                ) : (
                  "Generate Insights"
                )}
              </Button>
            </DialogFooter>
            {isError && <p className="text-red-500">Error: {error.message}</p>}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default NewInterview;
