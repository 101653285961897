import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Trash } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import useFetchDeleteInterview from "pages/Dashboard/fetchDeleteInterview";

const GuestCard = ({ guest_name, guest_linkedin, podcast_theme, interview_id, enabled }) => {
  const { mutate: deleteInterview } = useFetchDeleteInterview();
  const [showAlert, setShowAlert] = useState(false);

  const handleOnClickInterview = () => {
    if (enabled === 0) {
      console.log(enabled)
      setShowAlert(true);
    } else {
      window.location.href = `/research/${interview_id}`;
    }
  };

  const handleOnClickDelete = () => {
    deleteInterview(interview_id);
  };

  return (
    <Card className="w-[350px] h-max">
      <CardHeader onClick={handleOnClickInterview}>
        <CardTitle>{guest_name}</CardTitle>
        <CardDescription>
          <Badge variant="outline">{podcast_theme}</Badge>
        </CardDescription>
      </CardHeader>
      <CardContent>
        <CardDescription>
          <div className="w-full flex flex-row justify-between">
            <Button variant="link" className="pl-0 max-w-[90%] min-w-[90%] justify-start">
              <a className="truncate ..." href={guest_linkedin} target="_blank" rel="noreferrer">
                {guest_linkedin}
              </a>
            </Button>
            <Button variant="ghost" size="sm" onClick={handleOnClickDelete}>
              <Trash className="size-4" />
            </Button>
          </div>
        </CardDescription>
      </CardContent>

      {showAlert && (
        <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
          <AlertDialogTrigger asChild>
            <Button variant="ghost" className="hidden">Open</Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Go Pro To access all the interviews</AlertDialogTitle>
              <AlertDialogDescription>
                You need to upgrade to the Pro plan to access all the interviews.
                Your current plan only allows you to access one interview.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogAction onClick={() => setShowAlert(false)}>Continue</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </Card>
  );
};

export default GuestCard;