import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;

const useFetchResearch = (guest_id) => {
  const { getToken } = useAuth();

  const fetchResearch = async () => {
    const token = await getToken();

    // const response = await fetch(`https://api.zerotocast.com/interview/${guest_id}`,
    const response = await fetch(
      `${API_URL}/interview/${guest_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data[0];
  };

  return useQuery({ queryKey: ["reseach"], queryFn: fetchResearch });
};

export default useFetchResearch;
