import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import SignInPage from './pages/SignInPage/SignInPage';
import Create from './pages/Create/Create';
import Research from './pages/Research/Research';


import { useAuth } from "@clerk/clerk-react"


function App() {
  const { userId, isLoaded } = useAuth()

  return (
    <Router>
      <Routes>
        {
          !isLoaded ? null : userId ? (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/create" element={<Create />} />
              <Route path="/research/:id" element={<Research />} />
            </>
          ) : (
            <Route path="/" element={<Navigate to="/signin" />} />
          )
        }
        <Route path="/signin" element={<SignInPage />} />
      </Routes>
    </Router>
  );
}

export default App;
