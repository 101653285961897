import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth, useUser } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;


const useFetchCreateInterview = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const { user } = useUser();

  const createInterview = async (newInterview) => {
    const token = await getToken();
    const userId = user.id;

    // const response = await fetch("https://api.zerotocast.com/interview", {
    const response = await fetch(`${API_URL}/interview/${userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newInterview),
    });
    if (!response.ok) {
      throw new Error( (await response.json()).message  )  ;
    }
    return response.json(); // Return the parsed JSON data
  };

  return useMutation({
    mutationFn: createInterview,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["interviews"]);
      window.location.href = `/research/${data.interview_id}`;
    },
  });
};

export default useFetchCreateInterview;
