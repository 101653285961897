"use client";

import { useAtom } from "jotai";
import { LinkedInProfileAtom } from "../../../states/LinkedInProfileAtom";
import { useAuth } from "@clerk/clerk-react"

import { createInterview } from '../../../services/apiService';

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

// Defining the form schema

const formSchema = z.object({
  linkedin_url: z
    .string()
    .url({ message: "Invalid URL format." }) // Ensure valid URL format
    .min(2, { message: "URL must be at least 2 characters." }),
});

export function SearchProfile() {
  // Initializing the form
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      linkedin_url: "", // Set to an empty string or appropriate initial value
    },
  });

  // Using the Atom State

  const [profileUrl, setProfileUrl] = useAtom(LinkedInProfileAtom);
  const { userId } = useAuth()
  // Handling form submission

  const onSubmit = (data) => {
    
    setProfileUrl(data.linkedin_url);
    console.log(profileUrl);

    try {
      const response = createInterview(data.linkedin_url, userId);
      console.log(response);
      // Handle success (e.g., showing success message, redirection)
    } catch (error) {
      console.error(error);
      // Handle error (e.g., showing error message)
    }
  };


  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="  mt-8 space-y-8 w-96"
      >
        <FormField
          control={form.control}
          name="linkedin_url"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Linkedin Profile URL </FormLabel>
              <FormControl>
                <Input
                  placeholder="https://www.linkedin.com/in/biucky/"
                  {...field}
                />
              </FormControl>
              <FormDescription></FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">Create Interview</Button>
      </form>
    </Form>
  );
}
