import React from 'react';
import { SearchProfile } from '@/components/ui/searchProfile';
import { NavBar } from '@/components/ui/navBar';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card";    


const Create = () => {
    return (
        <div className='h-screen w-screen'>
            <NavBar/>
            <div className="h-screen w-screen flex justify-center items-center top-0 right-0 absolute">
                <Card>
                    <CardHeader>
                        <CardTitle>Create a New Interview</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <CardDescription>
                            Let's begin! for now we use LinkedIn profile URL to create an interview. <br></br>
                            more options will be available soon.
                        </CardDescription>
                        <SearchProfile/>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default Create;