import React from "react";

import {
  CassetteTape,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { UserButton } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";

// handle the click on the cassette tape icon to navigate to the home page

import useFetchUserStatus from "./fetchUserStatus";


//


function handleCassetteTapeClick() {
  window.location.href = '/'
}

function handleSubscribePro(user) {

  const user_email = user.primaryEmailAddress;
  console.log(user_email);
  window.open(`https://buy.stripe.com/9AQ29NdSkdrse2s6or?prefilled_email=${user_email}`, '_blank');
}

const NavigationBar = ({title}) => {
  
  const {user} = useUser();
  const { data } = useFetchUserStatus();
  
  return (
    <div>
      <header className="sticky top-0 z-10 flex h-[57px] items-center gap-1 border-b bg-background px-4">
        <Button variant="outline" size="icon" aria-label="Home" onClick={handleCassetteTapeClick}>
          <CassetteTape className="size-5" />
        </Button>

        <h1 className="text-xl font-semibold pl-3">{title}</h1>

        {data && (data.subscription !== "trialing" && data.subscription !== "active") ? (
          <Button
            variant="outline"
            size="sm"
            className="ml-auto gap-1.5 text-sm"
            onClick={() => handleSubscribePro(user)}
          >
            Upgrade to Pro
          </Button>
        ) : <div className="inline-flex ml-auto gap-1.5 text-sm"></div>}

        {/* if clerk is autho show userbutton  */}
        <UserButton />
      </header>
      <main className="grid flex-1 gap-4 overflow-auto p-4 md:grid-cols-2 lg:grid-cols-3">
        <div className="relative hidden flex-col items-start gap-8 md:flex"></div>
      </main>
    </div>
  );
};

export default NavigationBar;
