import React, { useState } from "react";
import NavigationBar from "@/components/ui/NavigationBar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Textarea } from "@/components/ui/textarea";
import useFetchResearch from "./fetchResearch";

const guest_id = window.location.pathname.split("/").pop();

const Research = () => {
  const { data, isLoading } = useFetchResearch(guest_id);

  const [intro, setIntro] = useState("");
  const [questions, setQuestions] = useState([]);

  React.useEffect(() => {
    if (!isLoading && data) {
      setIntro(data.intro);
      setQuestions(data.questions);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const theme = data.theme;
  const name = data.name;
  const linkedin_url = data.linkedin_url;
  const topics = data.topics;

  return (
    <div className="">
      <NavigationBar title="Research" />
      <div className="grid h-auto w-full pl-[56px] pr-[56px]">
        <div className="flex flex-col justify-start">
          <div>
            <div className="flex flex-row gap-5">
              <p className="text-2xl font-semibold">{name}</p>
              <Badge variant="outline" className="max-w-max">
                {theme}
              </Badge>
            </div>
            <Button variant="link" className="pl-0 pt-0 max-w-max">
              <a href={linkedin_url} target="_blank" rel="noreferrer">
                {linkedin_url}
              </a>
            </Button>
          </div>
        </div>
        <div>
          <Accordion type="multiple" defaultValue={["item-1", "item-2", "item-3"]} collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger>
                <h2 className="text-xl font-semibold">Intro</h2>
              </AccordionTrigger>
              <AccordionContent>
                <Textarea
                  value={intro}
                  onChange={(e) => setIntro(e.target.value)}
                  className="focus-visible:ring-none focus-visible:ring-0 min-h-[21vh]"
                />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>
                <h2 className="text-xl font-semibold">Topics</h2>
              </AccordionTrigger>
              <AccordionContent>
                {topics.map((topic) => (
                  <Badge variant="outline" key={topic}>
                    {topic}
                  </Badge>
                ))}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>
                <h2 className="text-xl font-semibold">Questions</h2>
              </AccordionTrigger>
              <AccordionContent>
                {questions.map((question) => (
                  <div className="max-h-10 flex mb-14">
<Textarea
                    key={question.id}
                    value={question}
                    // onChange={(e) => handleQuestionChange(question.id, e.target.value)}
                    className="focus-visible:ring-none focus-visible:ring-0" 
                  />
                  </div>
                ))}
                {/* <div className="flex w-full justify-end">
                  <Button variant="default" className="mt-5">
                    More Questions
                  </Button>
                </div> */}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Research;
