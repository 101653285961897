// src/services/apiService.js

import axios from 'axios';
import API_BASE_URL from '../config/apiConfig';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

export const createInterview = async (linkedinUrl, hostId) => {
  try {
    const response = await axiosInstance.post('/create_interview', { linkedInUrl: linkedinUrl , hostId: hostId});
    return response.data;
  } catch (error) {
    // Handle or throw error
    throw error;
  }
};

// You can add more functions for other API calls here
