import { useQuery } from "@tanstack/react-query";
import { useAuth, useUser } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;



const useFetchInterviews = () => {
  
  const { getToken } = useAuth();
  const { user } = useUser();
  
  console.log("API_URL", API_URL);

  const fetchInterviews = async () => {
    const userId = user.id;
    const token = await getToken();

    // const response = await fetch("https://api.zerotocast.com/interviews/1");
    const response = await fetch(`${API_URL}/interviews/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  };

  return useQuery({ queryKey: ["interviews"], queryFn: fetchInterviews });
};

export default useFetchInterviews;
